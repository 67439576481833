<template>
    <v-main style="min-height: 80% !important;" >
        <v-container class="pb-12">
            <v-row>
                <v-col cols="12" class="py-0">
					<h2
                        :style="{ color: parameters.primaryColor }"
                        class="text-left mb-3"
                        :class="$mq != 'lg' ? 'mt-3': 'mt-6'"
                    >
                        Sobre a empresa
                    </h2>
                </v-col>
				<v-col cols="12">
					<p class="body-2" v-html="parameters.store.b2dsobre"></p>
				</v-col>
				<v-col cols="12" class="d-flex justify-center">
					<v-carousel
						show-arrows
						:style="{maxWidth: $mq != 'lg' ? '580px' : '800px' }"
						:height="$mq != 'lg' ? '400px' : '500px'"
						hide-delimiter-background
						delimiter-icon="mdi-minus"
					>
						<v-carousel-item
							v-for="(image, i) in factoryimages"
							:key="i"
							:src="image"
						></v-carousel-item>
					</v-carousel>
				</v-col>
				<v-col cols="12" class="pt-12">
                    <p id="lojasassociadas" class="text-h5 font-weight-bold" :style="{color: parameters.primaryColor}">Lojas Associadas</p>
					<v-skeleton-loader
						v-if="parameters.loadingRelatedStores"
						type="products"
						:types="{ products: 'image@4'}"
					></v-skeleton-loader>
					<template v-else v-for="state in parameters.states">
						<p 
							v-if="state.relatedStores.length > 0"
							class="text-left text-h6 text-decoration-underline pt-4"
							:style="{color: parameters.secondaryColor}"
							:key="`state-${state.cf4estado}`"
						>Estado {{ state.cf4nome }}</p>
						<v-row :key="`row-${state.cf4estado}`">
							<v-col 
								v-for="cf6 in state.relatedStores"
								:key="cf6.cf6repres"
								:cols="colsForRelatedStores"
								class="d-flex child-flex"
							>
								<v-list-item>
									<v-list-item-icon class="mr-4">
										<v-icon large :color="parameters.secondaryColor">mdi-map-marker</v-icon>
									</v-list-item-icon>
									<v-list-item-content>
										<v-list-item-title class="text-left" style="white-space: normal !important;">Loja {{cf6.cf6nome}}</v-list-item-title>
										<v-list-item-subtitle class="text-left" style="white-space: normal !important;">
											Cidade: {{ cf6.cf3nome }}
											<br/>
											Fone: {{ cf6.cf1fone }}
										</v-list-item-subtitle>
									</v-list-item-content>
								</v-list-item>
							</v-col>
						</v-row>
					</template>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>

<script>

export default {
	computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
		colsForRelatedStores(){
			if(this.$mq == 'lg')
				return 4
			if(this.$mq == 'md')
				return 6
			return 12
		},
		factoryimages(){
            return [
				`../../../../img/${process.env.VUE_APP_ENV_NAME}/factory/Foto_Fabrica_1${this.$mq == 'lg' ? '' : '_mobile'}.jpg`,
				// `../../../../img/${process.env.VUE_APP_ENV_NAME}/factory/Foto_Fabrica_2.jpg`,
				// `../../../../img/${process.env.VUE_APP_ENV_NAME}/factory/Foto_Fabrica_3.jpg`,
			]
        }
    },
	created(){
		this.$store.dispatch("parameters/findRelatedStores")
	}
}
</script>

<style>

</style>